import axios from 'axios'
import {
  CONTENT_CALL_SUCCESS,
  CONTENT_CALL_FAIL,
  CONTENT_CALL_INITIATED,
  CONTENTBYID_CALL_FAIL,
  CONTENTBYID_CALL_SUCCESS,
  CONTENTBYID_CALL_INITIATED,
  EDITCONTENT_CALL_SUCCESS,
  EDITCONTENT_CALL_INITIATED,
  EDITCONTENT_CALL_FAIL
} from './actionType'

import { showNotification } from '../notification/actionCreator'

import apiConfig from '../../config/apiConfig'

export function fetchContent(page, itemId, feedbackRating, contact, contentTitle) {
  return function(dispatch) {
    dispatch(contentApiCallInitiated())
    // return axios.get(`${apiConfig.adminApi.cms}/content/feedback_report?size=300000`)
    const params = [
      `page=${page}`,
      'size=100',
      itemId && `item_id=${itemId}`,
      feedbackRating && `feedback=${feedbackRating}`,
      contact && `contact=${contact}`,
      contentTitle && `content_title=${contentTitle}`,
    ]
    let url = `${apiConfig.adminApi.cms}/content/feedback_report?${params.filter(Boolean).join('&')}`
    return axios
      .get(url)
      .then(response => {
        dispatch(contentApiCallSuccess(response.data))
      })
      .catch(error => {
        dispatch(contentApiCallFail(error))
      })
  }
}
export function contentApiCallInitiated(data) {
  return {
    type: CONTENT_CALL_INITIATED,
    data
  }
}
export function contentApiCallSuccess(data) {
  return {
    type: CONTENT_CALL_SUCCESS,
    data
  }
}
export function contentApiCallFail(error) {
  return {
    type: CONTENT_CALL_FAIL,
    error
  }
}

export function fetchContentById(id) {
  return function(dispatch) {
    dispatch(contentByIdApiCallInitiated())
    return axios
      .get(`${apiConfig.adminApi.cms}/content/feedback_report/${id}`)
      .then(response => {
        dispatch(contentByIdApiCallSuccess(response.data))
      })
      .catch(error => {
        dispatch(contentByIdApiCallFail(error))
      })
  }
}
export function contentByIdApiCallInitiated(data) {
  return {
    type: CONTENTBYID_CALL_INITIATED,
    data
  }
}
export function contentByIdApiCallSuccess(data) {
  return {
    type: CONTENTBYID_CALL_SUCCESS,
    data
  }
}
export function contentByIdApiCallFail(error) {
  return {
    type: CONTENTBYID_CALL_FAIL,
    error
  }
}

export function editContentFeedback(contentFeedback) {
  // if (contentFeedback.resolved === 'T') {
  //   contentFeedback.resolved = true
  // } else {
  //   contentFeedback.resolved = false
  // }
  return function(dispatch) {
    dispatch(editContentApiCallInitiated())
    return axios
      .put(
        `${apiConfig.adminApi.cms}/content/edit_feedback_report`,
        contentFeedback
      )
      .then(response => {
        dispatch(showNotification(true, `Content Feedback updated`, 'success'))
        dispatch(editContentApiCallSuccess(response.data))
      })
      .catch(error => {
        dispatch(editContentApiCallFail(error))
      })
  }
}
export function editContentApiCallInitiated(data) {
  return {
    type: EDITCONTENT_CALL_INITIATED,
    data
  }
}
export function editContentApiCallSuccess(data) {
  return {
    type: EDITCONTENT_CALL_SUCCESS,
    data
  }
}
export function editContentApiCallFail(error) {
  return {
    type: EDITCONTENT_CALL_FAIL,
    error
  }
}
